<template>
  <BaseFacility>
  <template v-slot:title>
      <h1 class="py-4 page-title-main">
      {{ $t("routeTitles.facility.facility") }}
    </h1>
  </template>
    <template v-slot:default>
      <div class="wrapper elevation-1">
        <div>
          <div>
            <v-breadcrumbs :items="items">
              <template v-slot:divider>
                <v-icon>mdi-chevron-right</v-icon>
              </template>
            </v-breadcrumbs>
          </div>
          <v-simple-table class="table-custom">
          <template v-slot:default>
            <thead>
              <tr>
                <th style="color: #757575 !important">{{ $t("facility.settings.roomID") }}</th>
                <th style="color: #757575 !important">{{ $t("facility.settings.roomName") }}</th>
                <th style="color: #757575 !important">{{ $t("facility.settings.roomEditting") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in roomList" :key="item.id">
                <td>{{ item.id }}</td>
                <td>{{ item.name }}</td>
                <td>
                  <v-btn :disabled="!checkPerUser" class="mx-2 pencil" fab dark small @click="roomName = item.name ;roomOrder = item.order;roomId = item.id ;buttonEdit()">
                    <v-icon color="#3083DC">
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
          </v-simple-table>
          <div class="text-right mt-3">
          <v-btn color="#004F8B" @click="checkPerUser ? openDialog = true: ''" :disabled="!checkPerUser">
            <span style="color: #fff">{{
            $t("buttons.createNewRoom")
          }}</span>
          </v-btn>
          </div>
        </div>
      </div>

      <base-dialog
        width="400px"
        :visible="openDialog"
        @close="openDialog= false"
        :title="$t('buttons.titleCreateNewRoom')"
        titleClass="text--big text--gray"
        class="dialog"
      >
        <template v-slot:content>
          <v-form ref="form">
            <div class="d-flex mt-4">
              <label class="t-label">部屋名</label>
              <v-col >
                <v-text-field
                 :rules="[() => !!facilityNameManagement ||$t('facility.rentalList.rules.required'),
                    () => facilityNameManagement.length < maxInt ||$t('rules.isInvalid')]"
                  dense outlined
                  v-model="facilityNameManagement">
                  </v-text-field>
              </v-col>
          </div>
          </v-form>
        </template>

        <template v-slot:footer>
            <div class="text-right mt-1">
                <v-btn :disabled="!checkPerUser" large class="btn-cancel btn--pink mr-5" @click="openDialog = false; setDefaultValue()">{{ $t('buttons.cancel') }}</v-btn>
                <v-btn :disabled="!checkPerUser" color="#004F8B" large class="btn-create mr-3" @click="handleSubmit()"><span style="color: #fff">{{ $t('buttons.saveAndCreate') }}</span></v-btn>
            </div>
        </template>
      </base-dialog>
    </template>
  </BaseFacility>
</template>

<script>
import BaseFacility from '@/components/Facility/BaseFacility'
import BaseDialog from '../../../../../components/Dialog/BaseDialog.vue'
import { ROOM_LIST, ROOM_TYPE } from '@/api/graphql/facility-room'
import { mapState, mapMutations, mapActions } from 'vuex'
import { checkPermissionUserCurrent } from '@/utils/permissions'
import gql from 'graphql-tag'

export default {
  apollo: {
    roomList: {
      query: gql`${ROOM_LIST} #aa`,
      variables () {
        return {
          roomTypeId: parseInt(this.$route.query.id)
        }
      }
    },
    roomType: {
      query: gql`${ROOM_TYPE}`,
      variables () {
        return {
          roomTypeId: parseInt(this.$route.query.id)
        }
      }
    }
  },
  name: 'roomTypeSettings',
  data () {
    return {
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      roomId: null,
      roomName: '',
      roomOrder: null,
      maxInt: 256,
      roomList: [],
      // ============ DIALOG ============
      openDialog: false,
      listFacilityType: ['TVS施設', '-----'],
      facilityTypeSelected: 'TVS施設',
      facilityId: null,
      facilityNameManagement: '',
      newRoomTypeId: null,
      roomType: null
    }
  },

  computed: {
    ...mapState({
      selectedFacility: state => state.facilitySettings.selectedFacility,
      selectedRoomType: state => state.facilityRoomType.selectedRoomType
    }),
    items () {
      return [
        {
          text: this.$t('facility.settings.roomType'),
          disabled: false,
          href: '/facility/settings'
        },
        {
          text: this.roomType?.facility.name,
          disabled: false,
          href: '/facility/settings/room-type?id=' + this.roomType?.id
        },
        {
          text: this.roomType?.name,
          disabled: false
        }
      ]
    }
  },

  methods: {
    ...mapMutations({ setFacilityRoom: 'setFacilityRoom' }),
    ...mapActions({ actionCreate: 'createRoom' }),

    createRoom () {
      const facilityRoom = {
        name: this.facilityNameManagement,
        roomTypeId: this.$route.query.id
      }
      this.setFacilityRoom({ facilityRoom: facilityRoom })
      this.actionCreate()
      this.openDialog = false
      this.facilityNameManagement = ''
      this.$refs.form.resetValidation()
    },
    handleSubmit () {
      if (this.$refs.form.validate()) {
        this.createRoom()
      }
    },
    buttonEdit () {
      const facilityRoom = {
        name: this.roomName,
        order: this.roomOrder,
        id: this.roomId,
        roomTypeId: parseInt(this.$route.query.id)
      }
      this.setFacilityRoom({ facilityRoom: facilityRoom })

      const url = '/facility/settings/room-type/rooms/detail?'
      window.open(`
        ${url}id=${this.roomId}&facility=${this.selectedFacility}&roomType=${this.selectedRoomType}
      `)
    },
    setDefaultValue () {
      this.facilityNameManagement = ''
      this.$refs.form.resetValidation()
    }
  },
  components: {
    BaseFacility,
    BaseDialog
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  max-width: 500px;
  padding: 20px;
  background: var(--ffffff) 0% 0% no-repeat padding-box;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 5px #21212131!important;
  border-radius: 4px;
  opacity: 1;
}
.heading {
  font-size: 18px;
}
::v-deep {
  .v-data-table {
    tbody tr td {
      text-align: center;
    }
  }
  .page-title-sub, .v-breadcrumbs__item, .v-breadcrumbs__divider {
    font-weight: bold !important;
    color: #AAAAAA !important;
    font-size: 18px !important;
  }
  .v-breadcrumbs li:nth-child(even) {
    padding: 0;
  }
  .v-breadcrumbs li:nth-child(2n) {
    padding: 0;
  }
  .mdi-chevron-right::before {
    font-weight: bold;
  }
  .v-icon {
    font-size:22px
  }
}
.pencil {
  background: var(--ffffff) 0% 0% no-repeat padding-box !important;
  background: #FFFFFF 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  opacity: 1;
  height:28px;
  width:28px;
  color: #3083DC !important;
  &.--disabled {
    background: #ccc !important;
  }
}
th {
  text-align: center !important;
}
</style>
